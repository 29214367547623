@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap");
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  font-family: "Kanit", sans-serif;
}
body .container {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  gap: 0px 0px;
  grid-template-areas: "." ".";
}
body .container .header {
  height: 140px;
  border-bottom: 3px solid #ec1f24;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media (max-width: 680px) {
  body .container .header {
    height: 270px;
    padding-top: 40px;
  }
}
body .container .header a {
  position: absolute;
  left: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  font-weight: 700;
  color: #000;
}
body .container .header a img {
  width: 25px;
  height: 25px;
}
body .container .header h2 {
  font-size: 30px;
  text-align: center;
  line-height: 1.2;
}
body .container .header h2 span {
  color: #ec1f24;
}
body .container .header h2 img {
  margin-top: 15px;
}
body .container .header .header-content {
  width: 100%;
  max-width: 1000px;
  margin: 70px auto;
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
}
body .container .header .header-content h2 {
  text-align: left;
}
body .container .header .header-content .header-text {
  text-align: right;
  font-size: 25px;
  font-weight: bold;
}
body .container .content {
  background-image: url(../img/bg.png);
}
@media (max-width: 1050px) {
  body .container .content {
    padding: 30px;
  }
}
body .container .content .progress-bar-content {
  color: #ffffff;
  padding: 50px 0;
  text-align: left;
  width: 100%;
  max-width: 1000px;
  margin: auto;
}
body .container .content .progress-bar-content b {
  color: #f09d12;
}
body .container .content .progress-bar-content p {
  font-size: 14px;
  line-height: 18px;
}
body .container .content .progress-bar-content .nums {
  display: flex;
  justify-content: center;
  margin-top: 35px;
  font-size: 22px;
  font-weight: bold;
}
body .container .content .progress-bar-content .nums span {
  padding-right: 5px;
  color: #ffba31;
}
body .container .content .progress-bar-content .nums span small {
  position: relative;
  left: -3px;
}
body .container .content .progress-bar-content .ul-columns {
  display: flex;
  gap: 50px;
  font-size: 14px;
}
body .container .content .progress-bar-content .ul-columns ul {
  padding: 0 0 0 25px;
}
body .container .content .progress-bar-content .progress {
  background: #fff;
  height: 32px;
  border-radius: 100px;
  margin: 20px 0 70px 0;
  position: relative;
  display: flex;
  align-items: center;
}
body .container .content .progress-bar-content .progress .bar {
  background: rgb(58, 50, 254);
  background: linear-gradient(90deg, rgb(58, 50, 254) 0%, rgb(139, 54, 255) 100%);
  width: 100%;
  height: 24px;
  border-radius: 100px;
  margin: 6px;
}
body .container .content .progress-bar-content .progress .perc {
  position: absolute;
  top: 45px;
  font-size: 19px;
  left: 0%;
  font-weight: bold;
}
body .container .content .how-to-buy-tils {
  width: 100%;
  max-width: 1000px;
  margin: 70px auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
body .container .content .how-to-buy-tils h3 {
  color: #fff;
  display: flex;
  justify-content: center;
  font-size: 25px;
  margin-bottom: 0;
}
body .container .content .how-to-buy-tils .main-tile {
  background: rgb(248, 164, 15);
  background: linear-gradient(90deg, rgb(248, 164, 15) 0%, rgb(250, 193, 22) 100%);
  padding: 30px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
body .container .content .how-to-buy-tils .main-tile .coins {
  display: flex;
  gap: 30px;
  font-size: 33px;
  align-items: center;
  font-weight: 700;
}
body .container .content .how-to-buy-tils .main-tile p {
  margin: 0;
  font-size: 18px;
  max-width: 400px;
  text-align: center;
}
body .container .content .how-to-buy-tils .main-tile b {
  font-size: 18px;
  font-weight: 800;
}
body .container .content .how-to-buy-tils .main-tile .buttons {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}
body .container .content .how-to-buy-tils .main-tile .buttons a {
  background-color: #000;
  color: #fff;
  padding: 10px 45px;
  text-decoration: none;
  border-radius: 100px;
  font-weight: 600;
  transition: all ease-in-out 0.2s;
}
body .container .content .how-to-buy-tils .main-tile .buttons a:hover {
  background-color: red;
}
body .container .content .how-to-buy-tils .other-tiles {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
body .container .content .how-to-buy-tils .other-tiles .sub-tile {
  padding: 30px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
}
body .container .content .how-to-buy-tils .other-tiles .sub-tile .more-coins {
  display: flex;
  flex-direction: column;
  line-height: 1;
}
body .container .content .how-to-buy-tils .other-tiles .sub-tile .coins {
  display: flex;
  gap: 30px;
  font-size: 33px;
  align-items: center;
  font-weight: 700;
}
body .container .content .how-to-buy-tils .other-tiles .sub-tile p {
  margin: 0;
  font-size: 16px;
  max-width: 400px;
  text-align: center;
}
body .container .content .how-to-buy-tils .other-tiles .sub-tile b {
  font-size: 18px;
  font-weight: 800;
}
body .container .content .how-to-buy-tils .other-tiles .sub-tile .buttons {
  margin-bottom: 30px;
}
body .container .content .how-to-buy-tils .other-tiles .sub-tile .buttons b {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
body .container .content .how-to-buy-tils .other-tiles .sub-tile .buttons .btns-row {
  display: flex;
  gap: 15px;
}
body .container .content .how-to-buy-tils .other-tiles .sub-tile .buttons .btns-row a {
  background-color: #000;
  color: #fff;
  padding: 10px 45px;
  text-decoration: none;
  border-radius: 100px;
  font-weight: 600;
  transition: all ease-in-out 0.2s;
}
body .container .content .how-to-buy-tils .other-tiles .sub-tile .buttons .btns-row a:hover {
  background-color: red;
}
body .container .content .how-to-buy-tils .other-tiles .sub-tile.allchain {
  background-color: #006aff;
}
body .container .content .how-to-buy-tils .other-tiles .sub-tile.allchain .coins {
  color: #fff;
}
body .container .content .how-to-buy-tils .other-tiles .sub-tile.toobit {
  background-color: #fff;
}
body .container .content .how-to-buy-tils .other-tiles .sub-tile.toobit a {
  padding-left: 20px;
  padding-right: 20px;
}
@media (max-width: 680px) {
  body .container .content .how-to-buy-tils .other-tiles {
    flex-direction: column;
  }
}
body .container .content .desktop-box {
  width: 100%;
  max-width: 1535px;
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding: 0 30px;
  box-sizing: border-box;
}
body .container .content .desktop-box .guide-item .guide-screen {
  border-radius: 10px;
  width: 100%;
}
body .container .content .desktop-box .guide-item .guide-screen img {
  width: 100%;
}
body .container .content .guide-row {
  display: flex;
  flex-direction: row;
  gap: 60px 30px;
  width: 100%;
  max-width: 1535px;
  flex-wrap: wrap;
  padding: 0 50px;
  justify-content: center;
}
body .container .content .guide {
  padding: 80px 0;
}
body .container .content .guide .guide-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
body .container .content .guide-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-end;
}
body .container .content .guide-item .guide-point {
  display: flex;
  gap: 10px;
}
body .container .content .guide-item .guide-point .guide-number {
  background-color: #e40f1e;
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  color: #ffffff;
  border-radius: 100px;
  align-items: center;
  font-weight: 600;
}
body .container .content .guide-item .guide-point .guide-text {
  color: white;
  display: flex;
  align-items: flex-start;
  line-height: 1;
  flex-direction: column;
  justify-content: center;
}
body .container .content .guide-item .guide-point .guide-text span {
  color: #ffc600;
  margin: 0 5px;
}
body .container .content .guide-item .guide-point .guide-text a {
  color: #ffc600;
  display: contents;
}
body .container .content .guide-item .guide-screen img {
  border-radius: 10px;
}
body .container .content .footer {
  display: flex;
  justify-content: center;
  color: #fff;
  text-align: center;
  margin-bottom: 60px;
  flex-direction: column;
  line-height: 23px;
}
body .container .content .footer a {
  color: #fff;
  text-decoration: none;
}
body .container .content .footer a b {
  font-size: 28px;
}

.mfp-no-margins img.mfp-img {
  padding: 0;
}

/* position of shadow behind the image */
.mfp-no-margins .mfp-figure:after {
  top: 0;
  bottom: 0;
}

/* padding for main container */
.mfp-no-margins .mfp-container {
  padding: 0;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}/*# sourceMappingURL=styles.css.map */