@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

body{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  font-family: "Kanit", sans-serif;

  .container{
    min-height: 100vh;
    display: grid; 
    grid-template-columns: 1fr; 
    grid-template-rows: auto 1fr; 
    gap: 0px 0px; 
    grid-template-areas: 
      "."
      "."; 

    .header{
      height: 140px;
      border-bottom: 3px solid #ec1f24;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      @media (max-width: 680px) {
        height: 270px;
        padding-top: 40px;
      } 

      a {
        position: absolute;
        left: 10px;
        top: 10px;
        display: flex;
        align-items: center;
        gap: 8px;
        text-decoration: none;
        font-weight: 700;
        color: #000;

        img{
          width: 25px;
          height: 25px;
        }
      }

      h2{
        font-size: 30px;
        text-align: center;
        line-height: 1.2;
        span{
          color: #ec1f24;
        }
        img{
          margin-top: 15px;
        }
      }

      .header-content{
        width: 100%;
        max-width: 1000px;
        margin: 70px auto;
        display: flex;
        flex-direction: row;
        gap: 30px;
        justify-content: space-between;
        align-items: center;

        h2{
          text-align: left;
        }
        .header-text{
          text-align: right;
          font-size: 25px;
          font-weight: bold;
        }
      }

    }
    .content{
      background-image: url(../img/bg.png);

      @media (max-width: 1050px) {
        padding: 30px;
      } 

      .progress-bar-content{
        color: #ffffff;
        padding: 50px 0;
        text-align: left;
        width: 100%;
        max-width: 1000px;
        margin: auto;

        b{
          color: #f09d12;
        }

        p{
          font-size: 14px;
          line-height: 18px;
        }

        .nums {
          display: flex;
          justify-content: center;
          margin-top: 35px;
          font-size: 22px;
          font-weight: bold;
          span{
            padding-right: 5px;
            
            color: #ffba31;
            small{
              position: relative;
              left: -3px;
            }
          }
        }

        .ul-columns{
          display: flex;
          gap: 50px;
          font-size: 14px;

          ul{
            padding: 0 0 0 25px;
          }
        }

        .progress{
          background: #fff;
          height: 32px;
          border-radius: 100px;
          margin: 20px 0 70px 0;
          position: relative;
          display: flex;
          align-items: center;

          .bar {
            background: rgb(58, 50, 254);
            background: linear-gradient(90deg, rgb(58, 50, 254) 0%, rgb(139, 54, 255) 100%);
            width: 100%;
            height: 24px;
            border-radius: 100px;
            margin: 6px;
          }
          .perc{
            position: absolute;
            top: 45px;
            font-size: 19px;
            left: 0%;
            font-weight: bold;
          }
        }


      }

      .how-to-buy-tils {
        width: 100%;
        max-width: 1000px;
        margin: 70px auto;
        display: flex;
        flex-direction: column;
        gap: 30px;

        h3{
          color: #fff;
          display: flex;
          justify-content: center;
          font-size: 25px;
          margin-bottom: 0;
        }

        .main-tile{
          background: rgb(248,164,15);
          background: linear-gradient(90deg, rgba(248,164,15,1) 0%, rgba(250,193,22,1) 100%);
          padding: 30px;
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 30px;
          .coins {
            display: flex;
            gap: 30px;
            font-size: 33px;
            align-items: center;
            font-weight: 700;
          }
          p{
            margin: 0;
            font-size: 18px;
            max-width: 400px;
            text-align: center;
          }
          b{
            font-size: 18px;
            font-weight: 800;
          }
          .buttons{
            display: flex;
            gap: 20px;
            margin-bottom: 30px;
            a{
              background-color: #000;
              color: #fff;
              padding: 10px 45px;
              text-decoration: none;
              border-radius: 100px;
              font-weight: 600;
              transition: all ease-in-out .2s;

              &:hover{
                background-color: red;
              }
            }
          }
        }

        .other-tiles {
          display: flex;
          justify-content: space-between;
          gap: 30px;

          .sub-tile{
            padding: 30px;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;
            justify-content: space-between;
            
            .more-coins{
              display: flex;
              flex-direction: column;
              line-height: 1;

              span{
                // max-height: 36px;
              }
            }

            .coins {
              display: flex;
              gap: 30px;
              font-size: 33px;
              align-items: center;
              font-weight: 700;
            }
            p{
              margin: 0;
              font-size: 16px;
              max-width: 400px;
              text-align: center;
            }
            b{
              font-size: 18px;
              font-weight: 800;
            }
            .buttons{
              margin-bottom: 30px;

              b{
                display: flex;
                justify-content: center;
                margin-bottom: 10px;
              }
              .btns-row{

                display: flex;
                gap: 15px;

                a{
                  background-color: #000;
                  color: #fff;
                  padding: 10px 45px;
                  text-decoration: none;
                  border-radius: 100px;
                  font-weight: 600;
                  transition: all ease-in-out .2s;
    
                  &:hover{
                    background-color: red;
                  }
                }
              }
            }
          }

          .sub-tile.allchain{
            background-color: #006aff;
            .coins{
              color: #fff;
            }
          }

          .sub-tile.toobit{
            background-color: #fff;

            a{
              padding-left: 20px;
              padding-right: 20px;
            }
          }

          @media (max-width: 680px) {
            flex-direction: column;
          } 
        }

      }

      .desktop-box {
        width: 100%;
        max-width: 1535px;
        display: flex;
        flex-direction: column;
        gap: 60px;
        padding: 0 30px;
        box-sizing: border-box;
        .guide-item .guide-screen {
          border-radius: 10px;
          width: 100%;

          img{
            width: 100%; 
          }
        }

      }

      .guide-row{
        display: flex;
        flex-direction: row;
        gap: 60px 30px;
        width: 100%;
        max-width: 1535px;
        flex-wrap: wrap;
        padding: 0 50px;
        justify-content: center;
      }

      .guide{
        padding: 80px 0;
        .guide-content{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 50px;
        }
      }

      .guide-item{
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: flex-end;
        .guide-point{
          display: flex;
          gap: 10px;
          .guide-number{
            background-color: #e40f1e;
            width: 33px;
            height: 33px;
            display: flex;
            justify-content: center;
            color: #ffffff;
            border-radius: 100px;
            align-items: center;
            font-weight: 600;
          }
          .guide-text {
            color: white;
            display: flex;
            align-items: flex-start;
            line-height: 1;
            flex-direction: column;
            justify-content: center;
            span{
              color: #ffc600;
              margin: 0 5px;
            }
            a{
              color: #ffc600;
              display:contents;
            }
          
          }
        }
        .guide-screen{
          img{
            border-radius: 10px;
          }
        }
      }

      .footer{
        display: flex;
        justify-content: center;
        color: #fff;
        text-align: center;
        margin-bottom: 60px;
        flex-direction: column;
        line-height: 23px;
        a{
          color: #fff;
          text-decoration: none;
          b{
            font-size: 28px;
          }          
        }
      }
    }
  }
}

.mfp-no-margins img.mfp-img {
	padding: 0;
}
/* position of shadow behind the image */
.mfp-no-margins .mfp-figure:after {
	top: 0;
	bottom: 0;
}
/* padding for main container */
.mfp-no-margins .mfp-container {
	padding: 0;
}



.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
	opacity: 0;
	-webkit-backface-visibility: hidden;
	-webkit-transition: all 0.3s ease-out; 
	-moz-transition: all 0.3s ease-out; 
	-o-transition: all 0.3s ease-out; 
	transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
		opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
		opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container, 
.mfp-with-zoom.mfp-removing.mfp-bg {
	opacity: 0;
}